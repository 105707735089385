<template>
  <div>
    <CRow>
      <CCol sm="4">
        <CCard border-color="success">
          <CCardHeader>ກະລຸນາປ້ອນຂໍ້ມູນ</CCardHeader>
          <CCardBody>
            <ValidationObserver v-slot="{ invalid }">
              <CForm ref="form" :model="form" @submit.prevent="handleAdd">
                <CRow>
                  <CCol sm="12">
                    <validation-provider rules="required">
                      <div class="form-group">
                        <label>ເລກ Serial :</label>
                        <v-select
                          prop="appl_serial"
                          v-model="form.appl_serial"
                          :options="appl"
                          :reduce="appl_serial => appl_serial.appl_serial"
                          label="appl_serial"
                        />
                      </div>
                    </validation-provider>
                  </CCol>
                  <CCol sm="12">
                    <validation-provider rules="required">
                      <CInput
                        label="ຈໍານວນ :"
                        type="number"
                        prop="add_amount"
                        v-model="form.add_amount"
                      />
                    </validation-provider>
                  </CCol>
                  <CCol sm="12">
                    <CButton type="submit" :disabled="invalid" color="info" class="float-right">
                      <CIcon name="cil-check" />ຕົກລົງ
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="8">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">ລດ</th>
              <th scope="col">ເລກ Serial</th>
              <th scope="col">ຈໍານວນ</th>
              <th scope="col">ຜູ້ເພີ້ມ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in applAddList" :key="row.index">
              <th>{{row.value.index}}</th>
              <th>{{row.value.appl_serial}}</th>
              <th class="float-right">{{row.value.add_amount | numFormat}}</th>
              <th>{{row.value.add_by}}</th>
            </tr>
          </tbody>
        </table>
      </CCol>
      <CCol sm="12">
        <div class="float-right">
          <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
            <CButton type="submit" variant="outline" color="primary">
              <CIcon name="cil-save" />ບັນທຶກ
            </CButton>
            <CButton @click="handleCancle" variant="outline" color="warning">
              <CIcon name="cil-x" />ຍົກເລີກ
            </CButton>
          </CForm>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions } from "vuex";
import { decrypt } from "@/libs/crypto";
export default {
  name: "AddApplFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    appl: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        appl_serial: "",
        add_amount: "1",
      },
      applAddList: [],
    };
  },
  methods: {
    ...mapActions("applAdd", ["applianceAdd"]),
    handleAdd() {
      let appl_serial = this.form.appl_serial;
      let add_amount = this.form.add_amount;
      let add_by = decrypt(this.$store.getters.getFullName);
      this.applianceAdd({
        appl_serial,
        add_amount,
        add_by,
      })
        .then((response) => {
          // Refresh data on applAddList
          this.applAddList = this.$store.getters.getApplAdd;
          // Reset data
          this.form.appl_serial = "";
          this.form.add_amount = "1";
        })
        .catch((error) => {
          this.$awn.alert("Add Error, Please try again");
        });
    },
    handleCancle() {
      this.form.appl_serial = "";
      this.applAddList = [];
      this.$emit("on_cancle", {});
    },
    handleSubmit() {
      this.form.appl_serial = "";
      this.$emit("on_success", {
        applAddList: this.applAddList,
      });
    },
  },
};
</script>